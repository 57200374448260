// 
// faq.scss
//

.question-q-box {
    height: 30px;
    width: 30px;
    line-height: 30px;
}


